import styles from './PageLoading.module.scss';

/**
 *
 * Page loading component, slim splash screen, without logo
 *
 * @returns Page loading component
 */
const PageLoading = ({ overlay }) => {
  return <div className={overlay ? styles.overlayLoader : styles.loader}></div>;
};
export default PageLoading;
